import { AnimatePresence, motion } from "framer-motion";
import { Index } from "react-instantsearch";
import { Flex } from "sparta";
import Awards from "./awards";
import Companies from "./companies";
import DailyTake from "./dailyTake";
import Opportunities from "./opportunities";
import { useRootLoaderData } from "../../root";

export default function ResultsData({
  selectedTab,
  dailyTakeLink,
  companiesLink,
  opportunitiesLink,
  opportunitiesData,
  opportunitiesLoading,
  awardsLink,
  awardsData,
  awardsLoading,
}) {
    const { canSeeOpportunitiesAtSearch, canSeeAwardsAtSearch } = useRootLoaderData();
  
  return (
    <Flex id="all_categories" direction="column" py="0" gap="0">
      {
        <AnimatePresence mode="wait">
          <motion.div
            key={selectedTab ? selectedTab : "empty"}
            initial={{ y: 10, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -10, opacity: 0 }}
            transition={{ duration: 0.2 }}
          >
            <Index indexName="posts">
              <DailyTake
                visible={selectedTab === "All Categories" || selectedTab === "Daily Take"}
                link={dailyTakeLink}
                isLoading={status === "loading"}
              />
            </Index>
            <Index indexName="sam_entities">
              <Companies
                visible={selectedTab === "All Categories" || selectedTab === "Companies"}
                link={companiesLink}
                isLoading={status === "loading"}
              />
            </Index>
            {/* TODO: Add People index and content */}
            {
              canSeeOpportunitiesAtSearch && (
                <Opportunities
                  link={opportunitiesLink}
                  data={opportunitiesData?.results || []}
                  isLoading={!!opportunitiesLoading || false}
                  visible={selectedTab === "All Categories" || selectedTab === "Opportunities"}
                />
              )
            } 
            {
              canSeeAwardsAtSearch && (
                <Awards
                  link={awardsLink}
                  data={awardsData?.results || []}
                  isLoading={!!awardsLoading || false}
                  visible={selectedTab === "All Categories" || selectedTab === "Awards"}
                />
              )
            }
          </motion.div>
        </AnimatePresence>
      }
    </Flex>
  );
}
