import { Flex, Skeleton } from "sparta";

interface IGeneralLoader {
    isLoading: boolean;
    children: React.ReactNode;
    justify?: "center" | "start" | "end" | "between";
}

export default function GeneralLoader({ isLoading, children, justify = "center" }: IGeneralLoader) {
    return(
        <Flex align="center" justify={justify}>
            {children}
        </Flex>
    );
};