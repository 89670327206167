import { Link } from "@remix-run/react";
import { useHits } from "react-instantsearch";
import { Badge, Flex, Heading, TableV2 } from "sparta";
import GeneralLoader from "./generalLoader";
import { useGlobalSearchOverlay } from "./main";

interface ICompanies {
  isLoading: boolean;
  link: string;
  visible: boolean;
}

export default function Companies({ isLoading, link, visible }: ICompanies) {
  const overlaySearch = useGlobalSearchOverlay();
  const { hits } = useHits();

  if (hits.length <= 0 || !visible) {
    return null;
  }

  return (
    <Flex id="companies" direction="column" py="3" gap="4">
      <Heading size={"3"}>Companies</Heading>
      <TableV2.Root variant="surface">
        <TableV2.Header>
          <TableV2.Row>
            <TableV2.ColumnHeaderCell>
              <GeneralLoader isLoading={isLoading} justify="start">
                Name
              </GeneralLoader>
            </TableV2.ColumnHeaderCell>
            <TableV2.ColumnHeaderCell>
              <GeneralLoader isLoading={isLoading} justify="start">
                NAICS Code
              </GeneralLoader>
            </TableV2.ColumnHeaderCell>
            <TableV2.ColumnHeaderCell>
              <GeneralLoader isLoading={isLoading} justify="start">
                Status
              </GeneralLoader>
            </TableV2.ColumnHeaderCell>
          </TableV2.Row>
        </TableV2.Header>
        <TableV2.Body>
          {hits.map((x) => {
            return (
              <TableV2.Row>
                <TableV2.Cell minWidth={"330px"}>
                  <GeneralLoader isLoading={isLoading} justify="start">
                    <Link to={`/companies/${x.uei}`}>
                      {x.display_name || "-"}
                    </Link>
                  </GeneralLoader>
                </TableV2.Cell>
                <TableV2.Cell>
                  <GeneralLoader isLoading={isLoading} justify="start">
                    <Badge>{x.primary_naics || "-"}</Badge>
                  </GeneralLoader>
                </TableV2.Cell>
                <TableV2.Cell>
                  <GeneralLoader isLoading={isLoading} justify="start">
                    {x.status || "-"}
                  </GeneralLoader>
                </TableV2.Cell>
              </TableV2.Row>
            );
          })}
        </TableV2.Body>
      </TableV2.Root>
      <Flex align="center" justify="center">
        <Link
          onClick={() => {
            overlaySearch.setOpen(false);
          }}
          to={link}
        >
          {" "}
          See all results
        </Link>
      </Flex>
    </Flex>
  );
}