import { useLocation, useNavigate } from "@remix-run/react";
import { history } from "instantsearch.js/es/lib/routers";
import type { PropsWithChildren } from "react";
import { InstantSearch } from "react-instantsearch";
import { useRootLoaderData } from "~/root";
import { globalSearchClient, searchClient } from "~/sdk/qs3/typesenseClient";
import { handleSearchStateChange } from "./searchUtils";

const router = history();

export function Search({ children }: PropsWithChildren) {
  const navigate = useNavigate();
  const location = useLocation();
  const { canSeeGlobalSearch } = useRootLoaderData();

  function onSearchStateChange(updatedSearchState) {
    updatedSearchState.setUiState(updatedSearchState.uiState);
    const newPath = handleSearchStateChange({
      uiState: updatedSearchState.uiState,
      currentPath: location.pathname,
      router,
    });
    if (!canSeeGlobalSearch && newPath) {
      navigate(newPath);
    }
  };

  return (
    <InstantSearch
      searchClient={globalSearchClient}
      indexName="posts"
      onStateChange={onSearchStateChange}
      routing={true}
      future={{
        preserveSharedStateOnUnmount: true,
      }}
      stalledSearchDelay={100}
    >
      {children}
    </InstantSearch>
  );
}
