import {
  BellIcon,
  BookmarkIcon,
  GearIcon,
  HamburgerMenuIcon,
  MagnifyingGlassIcon,
  MoonIcon,
  SunIcon,
} from "@radix-ui/react-icons";
import { useLocation, useNavigate } from "@remix-run/react";
import { useEffect, useMemo, useRef, useState } from "react";
import { useSearchBox } from "react-instantsearch";
import { Box, Button, Flex, IconButton, Kbd, TextInput, useAppearance } from "sparta";
import { useGlobalSearchOverlay } from "~/components/globalSearch/main";
import { useSideNav } from "~/context/SideNavContext";
import { CrownIcon } from "~/icons";
import { useRootLoaderData } from "~/root";
import useBreakpoint from "~/utils/hooks/useBreakpoint";
import { useHotkeys } from "~/utils/hooks/useHotkeys";
import { useHydrated } from "~/utils/hooks/useHydrated";
import AccountAvatar from "../../AccountAvatar/AccountAvatar";
import { ActionIcon } from "../ActionIcon";

interface TopNavProps {
  children: React.ReactNode;
}

export function TopNav({ children }: TopNavProps) {
  const searchRef = useRef<HTMLInputElement>(null);
  const { platform, isloggedin, activePlatforms, canSeeGlobalSearch } = useRootLoaderData();
  const { theme, setTheme } = useAppearance();
  const isHydrated = useHydrated();
  const { setOpen } = useSideNav();
  const navigate = useNavigate();
  const location = useLocation();
  const { query, refine } = useSearchBox();
  const [inputValue, setInputValue] = useState(query);
  const [osKey, setOsKey] = useState<string>("");

  // Add hability to manipulate Global Search
  const overlaySearch = useGlobalSearchOverlay();

  useEffect(() => {
    if (query) {
      overlaySearch.setOpen(true);
    }
  }, []);

  useHotkeys([
    [
      "mod+k",
      () => {
        if (searchRef.current) {
          // Toggle focus or blur depending on the current focus state
          searchRef.current === document.activeElement ? searchRef.current.blur() : searchRef.current?.focus();
        }
      },
    ],
  ]);

  useEffect(() => {
    // Determine the OS and set the appropriate key combination
    if (navigator.platform.includes("Mac")) {
      setOsKey("cmd + k");
    } else {
      setOsKey("ctrl + k");
    }
  }, []);

  function setQuery(newQuery: string) {
    setInputValue(newQuery);
    refine(newQuery);
  }

  // Clear search input when navigating away from the search page
  useEffect(() => {
    if (!location.pathname.startsWith("/search")) {
      setInputValue("");
    }
  }, [location.pathname]);

  const onToggleTheme = () => {
    if (document) {
      if (!document.startViewTransition) setTheme(theme === "dark" ? "light" : "dark");
      document.startViewTransition(() => setTheme(theme === "dark" ? "light" : "dark"));
    }
  };

  const upgradeRedirect = () => {
    if (isloggedin) {
      const redirectPlatform: string = platform ?? "defense";
      navigate(`/settings/billing?platform=${redirectPlatform}`);
    }
  };

  const anonRedirect = () => {
    if (!isloggedin) {
      window.location.replace("https://g2xchange.com/pricing");
    }
  };

  const bookmarkRedirect = () => {
    if (isloggedin) {
      navigate(`/bookmark`);
    }
    if (!isloggedin) {
      navigate("/access/login");
    }
  };
  const statusRedirect = () => {
    window.location.replace("https://status.g2xchange.com/");
  };

  // Define required platforms
  const requiredPlatforms = ["Defense", "FedCiv", "Health"];

  // Check if any required platform is missing
  const hasAllPlatforms = requiredPlatforms.every((p) => activePlatforms?.includes(p as any));

  // Get the current URL pathname
  const currentPath = location.pathname.substring(1).toLowerCase();

  // Mapping for lowercase paths to capitalized forms
  const pathMap: { [key: string]: string } = {
    defense: "Defense",
    fedciv: "FedCiv",
    health: "Health",
  };

  // Convert the current path to its capitalized form
  const capitalizedPath = pathMap[currentPath];

  // Check if the capitalized path is in active platforms
  const showUpgradeButtonBasedOnPath = !activePlatforms?.includes(capitalizedPath as any);

  // Check if any required platform is missing or if the current path-based condition is met
  const shouldShowUpgradeButton = !hasAllPlatforms || showUpgradeButtonBasedOnPath;

  // Final condition to determine if the "Upgrade Plan" button should be shown
  const showUpgradeButton = currentPath === "" || shouldShowUpgradeButton;

  // Added isMobile prop here to hide cmd + k in mobile
  const isMobile = useBreakpoint("mobile");

  return (
    <Flex direction="column" gap="0" flexGrow="1">
      <Flex height="40px" justify="between" align="center" style={{ borderBottom: "1px solid var(--gray-a6)" }} px="1">
        <Flex align="center" p="1" width="100%">
          <Flex display={{ initial: "flex", md: "none" }}>
            <IconButton
              variant="ghost"
              mx="1"
              onClick={() => {
                setOpen(true);
              }}
            >
              <HamburgerMenuIcon />
            </IconButton>
          </Flex>
          <Box width={{ initial: "100%", md: "600px" }}>
            <TextInput
              ref={searchRef}
              value={inputValue}
              onChange={(e) => {
                setQuery(e.currentTarget.value);
                if (canSeeGlobalSearch) {
                  overlaySearch.setOpen(true);
                }
              }}
              style={{ width: "100%", fontSize: isMobile ? "16px" : "inherit" }}
              placeholder={`Search`}
              leftSection={<MagnifyingGlassIcon />}
              rightSection={isMobile ? null : <Kbd>{osKey}</Kbd>}
            />
          </Box>
          <Box display={{ initial: "none", md: "block" }}>
            {!isloggedin ? (
              <Button onClick={anonRedirect} variant="ghost" highContrast leftIcon={<CrownIcon />} ml="2">
                Join the Community
              </Button>
            ) : (
              showUpgradeButton && (
                <Button onClick={upgradeRedirect} variant="ghost" highContrast leftIcon={<CrownIcon />} ml="2">
                  Upgrade plan
                </Button>
              )
            )}
          </Box>
          <Flex display={{ initial: "flex", md: "none" }}>
            {isHydrated ? (
              <ActionIcon onClick={onToggleTheme}>{theme === "dark" ? <SunIcon /> : <MoonIcon />}</ActionIcon>
            ) : null}
            <AccountAvatar />
          </Flex>
        </Flex>
        <Flex display={{ initial: "none", md: "flex" }}>
          <Button
            onClick={statusRedirect}
            variant="outline"
            color="green"
            style={{ borderRadius: "30px", marginTop: "5px", marginBottom: "5px" }}
          >
            Status
          </Button>
          <Flex data-accent-color="gray" gap="0">
            <ActionIcon onClick={bookmarkRedirect}>
              <BookmarkIcon />
            </ActionIcon>
            <ActionIcon to="/saved_searches">
              <BellIcon />
            </ActionIcon>
            <ActionIcon to="/settings/profile">
              <GearIcon />
            </ActionIcon>
            {isHydrated ? (
              <ActionIcon onClick={onToggleTheme}>{theme === "dark" ? <SunIcon /> : <MoonIcon />}</ActionIcon>
            ) : null}
            <Flex
              align="center"
              style={{
                borderLeft: "1px solid var(--gray-a6)",
              }}
              overflow="hidden"
              px="2"
            >
              <AccountAvatar />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      {children}
    </Flex>
  );
}
